import React, { Component } from 'react'
import { Label, Button, Col } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import ErrorDisplay from '../Common/ErrorDisplay'
import Alert from '../Common/Alert'
import PromotionEditorModal from './PromotionEditorModal'
import PromotionRequestService from '../../services/PromotionRequestService'
import moment from 'moment'
import PropertyService from '../../services/PropertyService'

export default class PromotionsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalDatesRequests: 0,
      loading: true,
      openModal: false,
      datesRequests: [],
      error: null,
      queryDatesRequestId: props.location.query?.datesRequestId,
      cachedProperties: {},
    }
    this.vendorId = props.params.id_vendor
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  handlePageChange = page => {
    this.setState({ page: page }, this.fetchData)
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { page, sizePerPage, queryDatesRequestId } = this.state
    try {
      const { rows: datesRequests, count } =
        await PromotionRequestService.getPage(this.vendorId, {
          page,
          limit: sizePerPage,
        })

      const properties = await Promise.all(
        datesRequests
          .filter((prop, i, self) => self.indexOf(self.find((p) => p.fk_property === prop.fk_property)) === i)
          .map(({ fk_property }) => PropertyService.getProperty(fk_property).then(({ result }) => result)),
      )

      this.setState({
        loading: false,
        datesRequests: datesRequests,
        totalDatesRequests: parseInt(count),
        cachedProperties: properties.reduce((acc, prop) => {
          acc[prop.id] = prop
          return acc
        }, {}),
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  onPageChange = (page) => {
    this.setState({ page }, this.fetchData)
  };

  openEditorModal = () => {
    this.setState({ openModal: true })
  };

  onEditorClose = (reload) => {
    this.setState({ openModal: false })
    if (reload) {
      this.fetchData()
    }
  };

  renderOfferLink = (cell, row) => {
    const prop = this.state?.cachedProperties?.[row.fk_property]
    return (
      <div className="offer-id">
        {prop?.id_salesforce_external}{' '}
        <a href={prop?.id_salesforce_external} className="link" target="_blank">
          (View)
        </a>
      </div>
    )
  };

  renderPropertyName = (cell, row) => {
    const prop = this.state?.cachedProperties?.[row.fk_property]
    return <div className="offer-id">{prop?.name}</div>
  };

  renderPackage(cell, row) {
    const packageDuration = row.package_nights ? 'nights' : 'days'

    return (
      <div>
        <div><strong>{row.package_name}</strong></div>
        <div>{row.package_nights || row.package_days} {packageDuration}</div>
      </div>
    )
  }

  renderDate = (key) => (cell, row) => moment.utc(row[key]).format('DD/MM/YYYY');

  renderPromotionType = (cell, row) => {
    switch (row.promotion_type) {
      case 'discount':
        return 'Discount'
      case 'inclusion':
        return 'Inclusion'
      default:
        return 'Discount & Inclusion'
    }
  };

  renderRate = (cell, row) => {
    const prop = this.state?.cachedProperties?.[row.fk_property]
    const room_type = prop?.room_types?.find((rType) => rType.id == row.fk_room)
    const rate = room_type?.room_rates?.find((rRate) => rRate.id == row.fk_rate)
    return rate?.rate_plan?.name
  };

  render() {
    const {
      openModal,
      datesRequests,
      page,
      sizePerPage,
      totalDatesRequests,
      loading,
      approvalModalShow,
    } = this.state

    return (
      <div className="container dates-requests">
        <Alert size="medium" show={approvalModalShow} onHide={() => { this.setState({ approvalModalShow: false }) }}>
          <h4>Thank you!</h4>
          <p style={{ textAlign: 'left' }}>
            A reminder that <b>this booking is not yet complete.</b> The Luxury
            Escapes customer support team has been notified to help the customer
            book these dates.{' '}
          </p>
        </Alert>
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={50} />
          </div>
        )}

        <div className="headings">
          <h3>Promotions</h3>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.setState({ openModal: true })
              }}
            >
              Create new promotion
            </Button>
          </div>
        </div>

        <PromotionEditorModal
          idVendor={this.vendorId}
          openModal={openModal}
          closeModal={this.onEditorClose}
        ></PromotionEditorModal>

        <div style={{ marginTop: '12px' }}>
          <BootstrapTable
            data={datesRequests}
            fetchInfo={{
              dataTotalSize: totalDatesRequests,
            }}
            options={{
              page,
              sizePerPage,
              sizePerPageList: [],
              onPageChange: this.onPageChange,
            }}
            remote
            pagination
            style={{ marginTop: '12px' }}
          >
            <TableHeaderColumn dataField="created_at" isKey>Date of request</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderPropertyName}>Property</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderRate} width="15%">Rate</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderPromotionType}>Promotion type</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderDate('start_date')}>Start date</TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.renderDate('end_date')}>End date</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}
